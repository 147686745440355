<template>
  <div :class="{ container: true, mg: $route.path === '/order-detail' }">
    <div class="row" v-if="isShowCkwl && addr.receiver">
      <div class="title">物流信息</div>
      <el-timeline :reverse="true" style="margin-left: 20px" v-if="orderTrack.length">
        <el-timeline-item v-for="(activity, index) in orderTrack" :key="index" :timestamp="activity.msgTime">
          {{ activity.content }}
        </el-timeline-item>
      </el-timeline>
      <div class="orderNoTrack" v-if="orderNoTrack && orderNoTrack.recv_addr">
        <div class="content">
          <div class="left">
            <div>送货地址</div>
          </div>
          <div class="right">
            <div>
              {{ orderNoTrack.recv_addr.province }} {{ orderNoTrack.recv_addr.city }}
              {{ orderNoTrack.recv_addr.county }}
              {{ orderNoTrack.recv_addr.addr }}
              {{ orderNoTrack.recv_addr.receiver }}
              {{ orderNoTrack.recv_addr.tel.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2') }}
            </div>
          </div>
        </div>
        <div class="content">
          <div class="left">
            <div>快递公司</div>
          </div>
          <div class="right">
            <div>{{ orderNoTrack.deliver_name }}</div>
          </div>
        </div>
        <div class="content">
          <div class="left">
            <div>快递单号</div>
          </div>
          <div class="right">
            <div>{{ orderNoTrack.deliver_code }}</div>
          </div>
        </div>
        <div class="other">请根据快递公司单号自行追踪物流</div>
      </div>
    </div>

    <div class="row" v-loading="loading" v-if="addr.receiver">
      <div class="title">收货信息</div>
      <el-row class="receive-info">
        <el-row type="flex" justify="start">
          <el-col class="col" :span="2">{{ addr.receiver }}</el-col>
          <el-col class="col">{{ addr.tel.substring(0, 3) }}****{{ addr.tel.substring(7, 11) }}</el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col class="col">{{ addr.address }}</el-col>
        </el-row>
      </el-row>
    </div>
    <div class="row" v-loading="loading">
      <div class="order-info">
        <el-row type="flex" justify="start">
          <el-col
            ><span>订单编号：</span><span>{{ order.no }}</span></el-col
          >
          <el-col
            ><span>订单状态：</span><span>{{ order.state }}</span></el-col
          >
        </el-row>
        <el-row type="flex" justify="start">
          <el-col
            ><span>订单时间：</span><span>{{ order.dateTime }}</span></el-col
          >
          <el-col
            ><span>供货商：</span><span>{{ order.vendor }}</span></el-col
          >
        </el-row>
        <el-row type="flex" justify="start" v-if="assign_mobile">
          <el-col
            ><span>手机号：</span><span>{{ assign_mobile }}</span></el-col
          >
        </el-row>
      </div>
      <el-row type="flex" class="goods-info">
        <div class="goods-box">
          <el-image style="width: 100px; height: 100px" :src="order.imgUrl"></el-image>
          <span class="goods-name">{{ order.name }}</span>
        </div>
        <div style="flex: 1">
          <el-row type="flex" justify="space-between" class="goods-info">
            <el-col
              ><span>礼品编号：</span><span>{{ order.code }}</span></el-col
            >
            <el-col
              ><span>点值：</span><span>{{ order.point }}</span></el-col
            >
            <el-col
              ><span>数量：</span><span>{{ order.count }}</span></el-col
            ></el-row
          >
        </div>
      </el-row>
    </div>
  </div>
</template>
<script>
import store from '@/store'
export default {
  created() {
    this.init()
  },
  data() {
    return {
      id: this.$route.query.id,
      isShowCkwl: this.$route.query.isShowCkwl === true || this.$route.query.isShowCkwl === 'true',
      orderId: this.$route.query.orderId,
      orderTrack: [],
      orderNoTrack: {},
      addr: {
        receiver: '',
        tel: '',
        address: '',
      },
      loading: true,
      order: {
        no: '',
        state: '',
        dateTime: '',
        vendor: '',
        imgUrl: '',
        name: '',
        code: '',
        point: '',
        count: '',
      },
      assign_mobile: '',
    }
  },
  methods: {
    async init() {
      await this.getOrderDetail()
      if (this.isShowCkwl) {
        this.getOrderTarck()
      }
    },
    async getOrderDetail() {
      const res = await store.dispatch('order/getOrderDetail', Number(this.id))
      this.loading = false
      if (res.data.ret_code === 0) {
        const d = res.data.data
        this.order.no = d.order_id
        this.order.state = d.state
        this.order.dateTime = d.create_at
        this.order.vendor = d.vendor
        this.order.imgUrl = d.image_url
        this.order.name = d.gift_name
        this.order.code = d.gift_code
        this.order.point = d.point
        this.order.count = d.count
        // 收货地址
        const addr = res.data.data.recv_addr
        this.addr.receiver = addr.receiver
        this.addr.tel = addr.tel
        this.addr.address = addr.province + ' ' + addr.city + ' ' + addr.county + ' ' + addr.addr
        this.assign_mobile = res.data.data.assign_mobile
      }
    },
    async getOrderTarck() {
      store.dispatch('order/getOrderTarck', this.order.no).then(res => {
        if (res.data.ret_code === 0) {
          if (res.data.data.jdOrderId) {
            this.orderTrack = res.data.data.orderTrack
          } else {
            this.orderNoTrack = res.data.data
          }
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.orderNoTrack {
  margin: -5px 10px 10px 10px;
  font-size: 16px;
  .content {
    display: flex;
    color: #999;
    line-height: 28px;
    .left {
      color: #999;
      min-width: 56px;
    }
    .right {
      margin-left: 20px;
      color: #000;
    }
  }
  .other {
    line-height: 28px;
  }
}
.container {
  padding: 20px;
  margin: 0 auto;
  background-color: #ffffff;
  max-width: 1160px;
  box-sizing: border-box;
  div.row:last-child {
    margin-bottom: 0;
  }
  .row {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    margin-bottom: 10px;
    .el-row {
      margin: 10px;
    }
    div.title {
      color: #333333;
      font-size: 16px;
      font-weight: blod;
      margin: 10px;
    }
    .col {
      font-size: 14px;
      color: #000000;
    }
    .receive-info {
      .el-row {
        .el-col {
          color: rgba(0, 0, 0, 1);
          font-size: 15px;
        }
      }
    }
    div.order-info {
      background-color: #f9f9f9;
      height: auto;
      padding: 5px;
      .el-row {
        .el-col {
          span {
            font-size: 16px;
            color: rgba(153, 153, 153, 1);
          }
          span:last-child {
            color: rgba(51, 51, 51, 1);
          }
        }
      }
    }
    .goods-info {
      line-height: 20px;
      align-items: center;
      .goods-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 40px;
      }
      .goods-name {
        margin-left: 40px;
        font-size: 14px;
        display: block;
      }
      .el-col:nth-child(2) {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 14px;
      }
      .el-col {
        span:first-child {
          font-size: 14px;
          color: #999999;
        }
        span:last-child {
          font-size: 14px;
          color: #333333;
        }
      }
    }
  }
}
.mg {
  margin-top: 20px;
}
</style>
